import React from "react";
import axios from "axios";
import { useState, useEffect } from "react";
import { Grid, Box, Typography } from "@mui/material";
//import { tokens } from "../../theme";
import moment from "moment";
import { WiHumidity } from "react-icons/wi";
import { IoRainyOutline } from "react-icons/io5";
import { FaEye, FaWind } from "react-icons/fa";
import { TbGrain } from "react-icons/tb";

const MetadataChart = ({ data }) => {
  //const theme = useTheme();
  //const colors = tokens(theme.palette.mode);

  const [selectedData, setSelectedData] = useState(null);

  useEffect(() => {
    // Function to randomly select Drive ID and Recording ID
    const getRandomData = () => {
      const randomIndex = Math.floor(Math.random() * data.length);
      return data[randomIndex];
    };

    // Update selected data every 5 minutes
    const interval = setInterval(() => {
      const randomData = getRandomData();
      setSelectedData(randomData);
    }, 5 * 60 * 1000);

    // Initial data selection
    const initialData = getRandomData();
    setSelectedData(initialData);

    return () => clearInterval(interval);
  }, [data]);

  if (!selectedData) {
    return <div>Loading...</div>;
  }

  console.log("selected data", selectedData);

  return (
    <Grid>
      <Box sx={{ m: 2 }}>
        <Box
          style={{
            display: "flex",
            justifyContent: "space-between",
          }}
        >
          <Typography
            variant="h5"
            fontWeight="600"
            sx={{ padding: "30px 30px 0 30px" }}
          >
            Drive: {selectedData["DriveID"]} | Recording:{" "}
            {selectedData["RecordingID"]}
          </Typography>

          <Typography
            variant="h5"
            fontWeight="600"
            sx={{ padding: "30px 30px 0 30px" }}
          >
            {moment(selectedData.Date).format("MMMM D, YYYY")}
          </Typography>
        </Box>

        <Box>
          <Typography
            variant="h1"
            fontWeight="600"
            sx={{ padding: "20px 30px 0 30px" }}
          >
            {selectedData.Temperature}°C
          </Typography>
          <Typography
            style={{ color: "#868B94" }}
            sx={{ padding: "5px 30px 0 30px" }}
          >
            {selectedData["WeatherConditions"]}
          </Typography>
        </Box>

        <Box
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            padding: "15px 30px",
            fontSize: "1rem",
          }}
        >
          <Box>
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                gap: "8px",
              }}
            >
              <FaWind style={{ color: "#868B94" }} />
              <span>{selectedData["WindSpeed"]} km/h</span>
            </Box>
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                gap: "8px",
              }}
            >
              <WiHumidity style={{ color: "#868B94" }} />
              <span>{selectedData.Humidity} %</span>
            </Box>
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                gap: "8px",
              }}
            >
              <IoRainyOutline style={{ color: "#868B94" }} />
              <span>{selectedData.Precipitation} mm </span>
            </Box>
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                gap: "8px",
              }}
            >
              <FaEye style={{ color: "#868B94" }} />
              <span>{selectedData.Visibility} m</span>
            </Box>
          </Box>
          <Box>
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                gap: "8px",
              }}
            >
              <TbGrain />
              <span>PM10: {selectedData.PM10} μg/m³</span>
            </Box>
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                gap: "8px",
              }}
            >
              <TbGrain />
              <span>PM2.5: {selectedData.PM25} μg/m³</span>
            </Box>
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                gap: "8px",
              }}
            >
              <TbGrain />
              <span>CO: {selectedData.CarbonMonoxide} μg/m³</span>
            </Box>
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                gap: "8px",
              }}
            >
              <TbGrain />
              <span>Dust: {selectedData.Dust} μg/m³</span>
            </Box>
          </Box>
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              gap: "8px",
            }}
          >
            {selectedData["Day/Night"] === "Day" ? (
              <img
                src={process.env.PUBLIC_URL + "/assets/day-icon.jpeg"}
                alt="Day Icon"
                style={{ width: "100px" }}
              />
            ) : (
              <img
                src={process.env.PUBLIC_URL + "/assets/night-icon.jpeg"}
                alt="Night Icon"
                style={{ width: "100px" }}
              />
            )}
          </Box>
        </Box>
      </Box>
    </Grid>
  );
};

const MetaDataDisplay = () => {
  const [recordingsData, setData] = useState();
  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get(
          `${process.env.REACT_APP_BACKEND_URL}/api/data/recordings`
        );
        setData(response.data);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchData();
  }, []);

  useEffect(() => {}, [recordingsData]);

  return (
    <div>
      {recordingsData ? (
        <MetadataChart data={recordingsData} />
      ) : (
        <p>Loading...</p>
        // Or any other loading indication you prefer
      )}
    </div>
  );
};

export default MetaDataDisplay;
