import React, { useState, useEffect } from "react";
import axios from "axios";
import { Box, useTheme } from "@mui/material";
import { DataGrid, GridToolbar } from "@mui/x-data-grid";
import { tokens } from "../../../theme";
import Header from "../../../global_components/parameterized/Header";

const IMAGES = () => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const [imagesData, setImagesData] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get(
          `${process.env.REACT_APP_BACKEND_URL}/api/data/images`
        );
        setImagesData(response.data);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchData();
  }, []);

  const columns = [
    {
      field: "DriveID",
      headerName: "Drive ID",
      flex: 1,
    },
    {
      field: "RecordingID",
      headerName: "Recording ID",
      flex: 1,
    },
    {
      field: "TimestampID",
      headerName: "Timestamp ID",
      flex: 1,
    },
    {
      field: "UnixTimestamp",
      headerName: "Unix Timestamp",
      flex: 1,
    },
    {
      field: "UTCTime",
      headerName: "UTC Time",
      flex: 1,
      valueFormatter: (params) => {
        return formatTimestamp(params.value);
      },
    },
    {
      field: "FilePath",
      headerName: "File Path",
      flex: 1,
    },
    {
      field: "FileType",
      headerName: "File Type",
      flex: 1,
    },
  ];

  const formatTimestamp = (timestamp) => {
    const timestampString = timestamp.toString(); // Convert to string
    //if (timestampString.length === 6) {}
    const hh = timestampString.substring(0, 2);
    const mm = timestampString.substring(2, 4);
    const ss = timestampString.substring(4, 6);
    return `${hh}:${mm}:${ss}`;
  };

  return (
    <Box m="20px">
      <Box display="flex" justifyContent="space-between">
        <Header title="IMAGES" subtitle="List of Images" />
        <Box display="flex" justifyContent="space-between" gap="10px">
          <Box display="flex" alignItems="center">
            <Box>
              <img
                alt="ipeg-logo"
                width="240px"
                height="55px"
                src={`../../assets/IPeG_Logo.png`}
              />
            </Box>
          </Box>
          <Box display="flex" alignItems="center">
            <Box>
              <img
                alt="dfki-logo"
                width="230px"
                height="53px"
                src={`../../assets/DFKI_Logo_2.png`}
              />
            </Box>
          </Box>
        </Box>
      </Box>
      {/* Changed the Height of the table */}
      <Box
        m="20px 0 0 0"
        // height="200"
        // width="100%"
        height="75vh"
        sx={{
          "& .MuiDataGrid-root": {
            border: "none",
          },
          "& .MuiDataGrid-cell": {
            borderBottom: "none",
          },
          "& .name-column--cell": {
            color: colors.greenAccent[300],
          },
          "& .MuiDataGrid-columnHeaders": {
            backgroundColor: colors.greenAccent[400],
            borderBottom: "none",
          },
          "& .MuiDataGrid-virtualScroller": {
            backgroundColor: colors.primary[400],
          },
          "& .MuiDataGrid-footerContainer": {
            borderTop: "none",
            backgroundColor: colors.greenAccent[400],
          },
          "& .MuiCheckbox-root": {
            color: `${colors.greenAccent[200]} !important`,
          },
        }}
      >
        <DataGrid
          checkboxSelection
          rows={imagesData}
          columns={columns}
          getRowId={(row) =>
            `${row["DriveID"]}-${row["RecordingID"]}-${row["TimestampID"]}`
          }
          slots={{
            toolbar: GridToolbar,
          }}
        />
      </Box>
    </Box>
  );
};

export default IMAGES;
