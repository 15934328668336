import React, { useEffect, useState } from "react";
import axios from "axios";
import dayjs from "dayjs";
import { Box } from "@mui/material";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider, StaticDatePicker } from "@mui/x-date-pickers";
import { styled } from "@mui/material/styles";
import { PickersDay } from "@mui/x-date-pickers/PickersDay";
import RecordingsDropdown from "./RecordingsDropdown";
import Collapse from "@mui/material/Collapse";
import Alert from "@mui/material/Alert";
import Header from "../../../global_components/parameterized/Header";

const HighlightedDay = styled(PickersDay)(({ theme }) => ({
  "&.Mui-selected": {
    backgroundColor: "#C5C5C5",
    color: "black",
  },
}));

const ServerDay = ({ highlightedDays = [], day, ...other }) => {
  const isSelected = highlightedDays.includes(day.format("YYYY-MM-DD"));

  return <HighlightedDay {...other} day={day} selected={isSelected} />;
};

const CalendarData = () => {
  const [driveDatesIDs, setDriveDatesIDs] = useState([]);
  const [selectedDay, setSelectedDay] = useState(new Date());
  const [recordings, setRecordings] = useState([]);
  const [error, setError] = useState(null);
  const [drive, setDrive] = useState(null);
  const [selectedRecording, setSelectedRecording] = useState("");

  useEffect(() => {
    const fetchDrives = async () => {
      try {
        const result = await axios(
          `${process.env.REACT_APP_BACKEND_URL}/api/data/fetched_drives`
        );
        const driveDatesIDs = result.data.map((item) => ({
          date: new Date(item.Date), // Assuming item.Date is in a format that can be directly used to create a Date object
          driveId: item.DriveID, // Replace with the actual property name for driveId in your API response
        }));
        setDriveDatesIDs(driveDatesIDs);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchDrives();
  }, []);

  // Function to fetch recordings for a drive
  const fetchRecordingsForDrive = async (driveId) => {
    try {
      const response = await axios(
        `${process.env.REACT_APP_BACKEND_URL}/api/data/drives/${driveId}/recordings`
      );
      return response.data;
    } catch (error) {
      console.error("Error fetching recordings:", error);
      setError("Error fetching recordings");
      return [];
    }
  };

  const handleDateChange = async (date) => {
    setSelectedRecording("");
    setSelectedDay(date);

    const formattedDate = date.format("YYYY-MM-DD"); // Format the selected date

    const foundDrive = driveDatesIDs.find((drive) =>
      dayjs(drive.date).isSame(formattedDate, "day")
    );

    setDrive(foundDrive);

    if (foundDrive) {
      const response = await fetchRecordingsForDrive(foundDrive.driveId);
      setRecordings(response);
      console.log(recordings);
      setError(null);
    } else {
      setError("No drive found for this date");
    }
  };

  return (
    <Box m="20px">
      <Box display="flex" justifyContent="space-between">
        <Header title="CALENDAR" subtitle="Select the date of the drive" />
        <Box display="flex" justifyContent="space-between" gap="10px">
          <Box display="flex" alignItems="center">
            <Box>
              <img
                alt="ipeg-logo"
                width="240px"
                height="55px"
                src={`../../assets/IPeG_Logo.png`}
              />
            </Box>
          </Box>
          <Box display="flex" alignItems="center">
            <Box>
              <img
                alt="dfki-logo"
                width="230px"
                height="53px"
                src={`../../assets/DFKI_Logo_2.png`}
              />
            </Box>
          </Box>
        </Box>
      </Box>
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          width: "30vw",
          mx: "auto",
        }}
      >
        <Box
          sx={{
            boxShadow: 1,
            width: "100%", // Set the width to 100% to make it the same as the container
          }}
        >
          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <StaticDatePicker
              orientation="portrait"
              defaultValue={dayjs()}
              selected={selectedDay}
              onChange={handleDateChange}
              slotProps={{
                toolbar: {
                  // Show the toolbar
                  hidden: false,
                },
                actionBar: {
                  actions: ["today"],
                },
              }}
              slots={{
                day: (props) => (
                  <ServerDay
                    {...props}
                    highlightedDays={driveDatesIDs.map((drive) =>
                      dayjs(drive.date).format("YYYY-MM-DD")
                    )}
                  />
                ),
              }}
            />
          </LocalizationProvider>
        </Box>
        <Collapse in={!!drive} animateOpacity sx={{ mt: 1, width: "100%" }}>
          <Alert severity="success" color="info" sx={{ fontSize: 14 }}>
            Drive found for this date
          </Alert>
        </Collapse>
        <Collapse in={!!error} animateOpacity sx={{ mt: 1, width: "100%" }}>
          <Alert severity="error" sx={{ fontSize: 14 }}>
            {error}
          </Alert>
        </Collapse>
        <Box sx={{ mt: 1, width: "100%" }}>
          <RecordingsDropdown
            recordings={recordings}
            drive={drive}
            selectedRecording={selectedRecording}
            setSelectedRecording={setSelectedRecording}
          />
        </Box>
      </Box>
    </Box>
  );
};

export default CalendarData;
