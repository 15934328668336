import React from "react";
import axios from "axios";
import { useState, useEffect } from "react";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Typography,
} from "@mui/material";

//import { tokens } from "../../theme";

const formatUTCtoTime = (utcTime) => {
  // Convert to string for easy manipulation
  let timeString = utcTime.toString();

  // Pad the string to ensure it has at least 6 characters (HHMMSS)
  timeString = timeString.padStart(6, "0");

  // Extract hours, minutes, and seconds
  let hours = timeString.substring(0, 2);
  let minutes = timeString.substring(2, 4);
  let seconds = timeString.substring(4, 6);
  console.log("utc", utcTime);
  console.log("minutes", minutes);
  console.log("seconds", seconds);

  return `${hours}:${minutes}:${seconds}`;
};

const HighStatsComponent = ({ driveData }) => {
  //const theme = useTheme();
  //const colors = tokens(theme.palette.mode);
  // Function to find the highest value for a given key
  const findHighest = (key) => {
    return driveData.reduce(
      (acc, curr) => (curr[key] > acc[key] ? curr : acc),
      driveData[0]
    );
  };

  // Function to find the earliest drive based on "StartTimeUTC"
  const findEarliestDrive = () => {
    return driveData.reduce(
      (acc, curr) => (curr["StartTimeUTC"] < acc["StartTimeUTC"] ? curr : acc),
      driveData[0]
    );
  };

  // Find the highest values
  const highestTemperature = findHighest("Temperature");
  const highestHumidity = findHighest("Humidity");
  const highestPercipitation = findHighest("Precipitation");
  const highestWindSpeed = findHighest("WindSpeed");
  const highestVisibility = findHighest("Visibility");
  const earliestDrive = findEarliestDrive();

  return (
    <TableContainer component={Paper}>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell style={{ textAlign: "center" }}>
              <Typography fontWeight="600">Category</Typography>
            </TableCell>
            <TableCell style={{ textAlign: "center" }}>
              <Typography fontWeight="600">Drive ID</Typography>
            </TableCell>
            <TableCell style={{ textAlign: "center" }}>
              <Typography fontWeight="600">Date</Typography>
            </TableCell>
            <TableCell style={{ textAlign: "center" }}>
              <Typography fontWeight="600">Value</Typography>
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          <TableRow>
            <TableCell style={{ textAlign: "center" }}>
              <Typography>Highest Temperature</Typography>
            </TableCell>
            <TableCell style={{ textAlign: "center" }}>
              <Typography>{highestTemperature["DriveID"]}</Typography>
            </TableCell>
            <TableCell style={{ textAlign: "center" }}>
              <Typography>{highestTemperature.Date}</Typography>
            </TableCell>
            <TableCell style={{ textAlign: "center" }}>
              <Typography>{highestTemperature.Temperature} °C</Typography>
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell style={{ textAlign: "center" }}>
              <Typography>Highest Humidity</Typography>
            </TableCell>
            <TableCell style={{ textAlign: "center" }}>
              <Typography>{highestHumidity["DriveID"]}</Typography>
            </TableCell>
            <TableCell style={{ textAlign: "center" }}>
              <Typography>{highestHumidity.Date}</Typography>
            </TableCell>
            <TableCell style={{ textAlign: "center" }}>
              <Typography>{highestHumidity.Humidity} %</Typography>
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell style={{ textAlign: "center" }}>
              <Typography>Highest Precipitation</Typography>
            </TableCell>
            <TableCell style={{ textAlign: "center" }}>
              <Typography>{highestPercipitation["DriveID"]}</Typography>
            </TableCell>
            <TableCell style={{ textAlign: "center" }}>
              <Typography>{highestPercipitation.Date}</Typography>
            </TableCell>
            <TableCell style={{ textAlign: "center" }}>
              <Typography>{highestPercipitation.Precipitation} mm</Typography>
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell style={{ textAlign: "center" }}>
              <Typography>Highest Wind Speed</Typography>
            </TableCell>
            <TableCell style={{ textAlign: "center" }}>
              <Typography>{highestWindSpeed["DriveID"]}</Typography>
            </TableCell>
            <TableCell style={{ textAlign: "center" }}>
              <Typography>{highestWindSpeed.Date}</Typography>
            </TableCell>
            <TableCell style={{ textAlign: "center" }}>
              <Typography>{highestWindSpeed["WindSpeed"]} km/h</Typography>
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell style={{ textAlign: "center" }}>
              <Typography>Highest Visibility</Typography>
            </TableCell>
            <TableCell style={{ textAlign: "center" }}>
              <Typography>{highestVisibility["DriveID"]}</Typography>
            </TableCell>
            <TableCell style={{ textAlign: "center" }}>
              <Typography>{highestVisibility.Date}</Typography>
            </TableCell>
            <TableCell style={{ textAlign: "center" }}>
              <Typography>{highestVisibility.Visibility} m</Typography>
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell style={{ textAlign: "center" }}>
              <Typography>Earliest Drive</Typography>
            </TableCell>
            <TableCell style={{ textAlign: "center" }}>
              <Typography>{earliestDrive["DriveID"]}</Typography>
            </TableCell>
            <TableCell style={{ textAlign: "center" }}>
              <Typography>{earliestDrive.Date}</Typography>
            </TableCell>
            <TableCell style={{ textAlign: "center" }}>
              <Typography>
                {formatUTCtoTime(earliestDrive["StartTimeUTC"])} GMT
              </Typography>
            </TableCell>
          </TableRow>
        </TableBody>
      </Table>
    </TableContainer>
  );
};

const MetaDataStatistics = () => {
  const [recordingsData, setData] = useState();
  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get(
          `${process.env.REACT_APP_BACKEND_URL}/api/data/recordings`
        );
        setData(response.data);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchData();
  }, []);

  useEffect(() => {}, [recordingsData]);

  return (
    <div>
      {recordingsData ? (
        <HighStatsComponent driveData={recordingsData} />
      ) : (
        <p>Loading...</p>
        // Or any other loading indication you prefer
      )}
    </div>
  );
};

export default MetaDataStatistics;
