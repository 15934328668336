import { Box, IconButton, Typography, useTheme } from "@mui/material";
import axios from "axios";
import { useState, useEffect } from "react";
import { tokens } from "../../../theme";
import DownloadOutlinedIcon from "@mui/icons-material/DownloadOutlined";
import Header from "../../../global_components/parameterized/Header";
import StatBox from "../../../global_components/parameterized/StatBox";
import MetaDataDisplay from "./MetaDataDisplay";
import MetaDataStatistics from "./MetaDataStatistics";
import DirectionsCarFilledIcon from "@mui/icons-material/DirectionsCarFilled";
import RadioButtonCheckedIcon from "@mui/icons-material/RadioButtonChecked";
import PhotoCameraIcon from "@mui/icons-material/PhotoCamera";
import SensorsIcon from "@mui/icons-material/Sensors";

import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  ResponsiveContainer,
  Legend,
  PieChart,
  Pie,
  Cell,
  AreaChart,
  Area,
} from "recharts";

const useDrivesData = () => {
  const [DrivesData, setDrivesData] = useState([]);

  useEffect(() => {
    const fetchDrivesData = async () => {
      try {
        const response = await axios.get(
          `${process.env.REACT_APP_BACKEND_URL}/api/data/drives`
        );
        setDrivesData(response.data);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchDrivesData();
  }, []);

  return DrivesData;
};

const useRecordingsData = () => {
  const [RecordingsData, setRecordingsData] = useState([]);

  useEffect(() => {
    const fetchRecordingsData = async () => {
      try {
        const response = await axios.get(
          `${process.env.REACT_APP_BACKEND_URL}/api/data/recordings`
        );
        setRecordingsData(response.data);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchRecordingsData();
  }, []);

  return RecordingsData;
};

const useImagesData = () => {
  const [ImagesData, setImagesData] = useState([]);

  useEffect(() => {
    const fetchImagesData = async () => {
      try {
        const response = await axios.get(
          `${process.env.REACT_APP_BACKEND_URL}/api/data/images`
        );
        setImagesData(response.data);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchImagesData();
  }, []);

  return ImagesData;
};

const useLidarData = () => {
  const [LidarData, setLidarData] = useState([]);

  useEffect(() => {
    const fetchLidarData = async () => {
      try {
        const response = await axios.get(
          `${process.env.REACT_APP_BACKEND_URL}/api/data/lidar`
        );
        setLidarData(response.data);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchLidarData();
  }, []);

  return LidarData;
};

const CustomTooltip = ({ active, payload, label, data }) => {
  if (active && payload) {
    // Create a mapping of driver name to cumulative duration up to the nth drive
    const cumulativeDurations = payload.reduce((acc, entry) => {
      const driverData = data.filter(
        (drive) => drive["DriverName"] === entry.name
      );
      const sortedDriverData = driverData.sort(
        (a, b) => a["DriveID"] - b["DriveID"]
      );
      let cumulativeDuration = 0;
      for (let i = 0; i < label && i < sortedDriverData.length; i++) {
        cumulativeDuration += sortedDriverData[i]["DurationMinutes"];
      }
      acc[entry.name] = cumulativeDuration;
      return acc;
    }, {});

    return (
      <div
        className="custom-tooltip"
        style={{
          backgroundColor: "#fff",
          padding: "10px",
          border: "1px solid #ccc",
        }}
      >
        {Object.entries(cumulativeDurations).map(
          ([driver, duration], index) => (
            <p
              key={`item-${index}`}
              style={{ color: payload.find((p) => p.name === driver).color }}
            >
              {`${driver}: ${duration} mins`}
            </p>
          )
        )}
      </div>
    );
  }

  return null;
};

const Dashboard = () => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);

  const DrivesData = useDrivesData();
  const RecordingsData = useRecordingsData();
  const ImagesData = useImagesData();
  const LidarData = useLidarData();

  // ######### Number of Images ######### //
  const numberOfLidar = LidarData.length;
  console.log(numberOfLidar);

  // ######### Number of Images ######### //
  const numberOfImages = ImagesData.length;
  console.log(numberOfImages);

  // ######### Number of recordings ######### //
  const numberOfRecordings = RecordingsData.length;
  console.log(numberOfRecordings);

  // ######### Number of drives ######### //
  const numberOfDrives =
    DrivesData.length > 0
      ? Math.max(...DrivesData.map((drive) => drive.DriveID))
      : 0;
  //console.log(numberOfDrives);

  // ######### DayNight ######### //
  // Process the data to count the number of drives during the day and night
  const dayCount = DrivesData.filter(
    (drive) => drive["DayNight"] === "Day"
  ).length;
  const nightCount = DrivesData.filter(
    (drive) => drive["DayNight"] === "Night"
  ).length;

  // Prepare the data for the Pie Chart
  const chartDayNightColors = ["#0088FE", "#00C49F"];
  const chartDayNightData = [
    { name: "Day", value: dayCount },
    { name: "Night", value: nightCount },
  ];

  // ######### Drives Per Year ######### //
  // Process the data to count the number of drives per year
  const drivesPerYear = DrivesData.reduce((acc, drive) => {
    const year = new Date(drive.Date).getFullYear();
    if (!acc[year]) {
      acc[year] = 0;
    }
    acc[year]++;
    return acc;
  }, {});
  // Prepare data for the DrivesPerYearData chart
  const chartDrivesPerYearData = Object.keys(drivesPerYear).map((year) => ({
    year,
    "Number of Drives": drivesPerYear[year],
  }));

  // ######### Available Sensors ######### //
  // Prepare data for the AvailableSensorsData chart
  const chartAvailableSensorsData = DrivesData.reduce(
    (acc, drive) => {
      if (drive.LIDAR === "Available") acc[0].value += 1;
      if (drive.Camera === "Available") acc[1].value += 1;
      if (drive.IMU === "Available") acc[2].value += 1;
      return acc;
    },
    [
      { sensor: "LiDAR", value: 0 },
      { sensor: "Camera", value: 0 },
      { sensor: "IMU", value: 0 },
    ]
  );

  // ######### Cumulative Duration ######### //
  // Prepare data for the CumulativeDurationData chart
  const chartCumulativeDurationColors = [
    "#1f77b4", // Muted blue
    "#2ca02c", // Cooked asparagus green
    "#A6ABBD", // Muted purple
    "#ff7f0e", // Safety orange
    "#d62728", // Brick red
    "#8c564b", // Chestnut brown
    "#e377c2", // Raspberry yogurt pink
    "#7f7f7f", // Middle gray
    "#bcbd22", // Curry yellow-green
    "#17becf", // Blue-teal
  ];
  // Extract unique driver names
  const drivers = Array.from(
    new Set(DrivesData.map((drive) => drive["DriverName"]))
  );
  const chartCumulativeDurationData = drivers.map((driver) => {
    let cumulativeDuration = 0;
    return {
      name: driver,
      data: DrivesData.filter((drive) => drive["DriverName"] === driver)
        .sort((a, b) => a["DriveID"] - b["DriveID"]) // Sort drives by Drive ID
        .map((drive) => {
          cumulativeDuration += drive["DurationMinutes"];
          return {
            DriveID: drive["DriveID"],
            CumulativeDuration: cumulativeDuration,
          };
        }),
    };
  });

  return (
    <Box m="20px">
      {/* HEADER */}
      <Box display="flex" justifyContent="space-between">
        <Header
          title="DASHBOARD"
          subtitle="Welcome to your field data dashboard"
        />
        <Box display="flex" justifyContent="space-between" gap="10px">
          <Box display="flex" alignItems="center">
            <Box>
              <img
                alt="ipeg-logo"
                width="240px"
                height="55px"
                src={`../../assets/IPeG_Logo.png`}
              />
            </Box>
          </Box>
          <Box display="flex" alignItems="center">
            <Box>
              <img
                alt="dfki-logo"
                width="230px"
                height="53px"
                src={`../../assets/DFKI_Logo_2.png`}
              />
            </Box>
          </Box>
        </Box>
      </Box>

      {/* GRID & CHARTS */}
      <Box
        display="grid"
        gridTemplateColumns="repeat(12, 1fr)"
        gridAutoRows="140px"
        gap="20px"
      >
        {/* ROW 1 */}
        <Box
          gridColumn="span 3"
          backgroundColor={colors.primary[400]}
          display="flex"
          alignItems="center"
          justifyContent="center"
        >
          <StatBox
            title={numberOfDrives}
            //title="12,361"
            subtitle="Number of Drives"
            progress="0.75"
            increase="+23%"
            icon={
              <DirectionsCarFilledIcon
                sx={{ color: colors.greenAccent[600], fontSize: "32px" }}
              />
            }
          />
        </Box>
        <Box
          gridColumn="span 3"
          backgroundColor={colors.primary[400]}
          display="flex"
          alignItems="center"
          justifyContent="center"
        >
          <StatBox
            title={numberOfRecordings}
            subtitle="Number of Recordings"
            progress="0.50"
            increase="+23%"
            icon={
              <RadioButtonCheckedIcon
                sx={{ color: colors.greenAccent[600], fontSize: "32px" }}
              />
            }
          />
        </Box>
        <Box
          gridColumn="span 3"
          backgroundColor={colors.primary[400]}
          display="flex"
          alignItems="center"
          justifyContent="center"
        >
          <StatBox
            title={numberOfImages}
            subtitle="Number of Images"
            progress="0.30"
            increase="+48%"
            icon={
              <PhotoCameraIcon
                sx={{ color: colors.greenAccent[600], fontSize: "32px" }}
              />
            }
          />
        </Box>
        <Box
          gridColumn="span 3"
          backgroundColor={colors.primary[400]}
          display="flex"
          alignItems="center"
          justifyContent="center"
        >
          <StatBox
            title={numberOfLidar}
            subtitle="Number of LiDAR point clouds"
            progress="0.80"
            increase="+41%"
            icon={
              <SensorsIcon
                sx={{ color: colors.greenAccent[600], fontSize: "32px" }}
              />
            }
          />
        </Box>

        {/* ROW 2 */}
        <Box
          gridColumn="span 4"
          gridRow="span 2"
          backgroundColor={colors.primary[400]}
        >
          <Box
            mt="25px"
            p="0 30px"
            display="flex "
            justifyContent="space-between"
            alignItems="center"
          >
            <Box>
              <Typography
                variant="h5"
                fontWeight="600"
                color={colors.grey[100]}
              >
                Drives Per Year
              </Typography>
            </Box>
            <Box>
              <IconButton>
                <DownloadOutlinedIcon
                  sx={{ fontSize: "26px", color: colors.greenAccent[500] }}
                />
              </IconButton>
            </Box>
          </Box>
          <Box height="250px" m="-20px 0 0 0">
            {/*<LineChart isDashboard={true} />*/}
            <ResponsiveContainer>
              <BarChart
                data={chartDrivesPerYearData}
                margin={{ top: 30, right: 30, left: 20, bottom: 5 }}
              >
                <CartesianGrid strokeDasharray="3 3" />
                <XAxis dataKey="year" />
                <YAxis />
                <Tooltip />
                <Bar dataKey="Number of Drives" fill="#4590F9" barSize={50} />
              </BarChart>
            </ResponsiveContainer>
          </Box>
        </Box>
        <Box
          gridColumn="span 4"
          gridRow="span 2"
          backgroundColor={colors.primary[400]}
          padding="30px"
        >
          <Typography
            variant="h5"
            fontWeight="600"
            sx={{ marginBottom: "15px" }}
          >
            Cumulative Drives Duration
          </Typography>
          <ResponsiveContainer>
            <AreaChart margin={{ top: 5, right: 30, left: 20, bottom: 5 }}>
              <CartesianGrid strokeDasharray="3 3" />
              <XAxis dataKey="DriveID" />
              <YAxis />
              <Tooltip content={<CustomTooltip data={DrivesData} />} />

              <Legend />
              {chartCumulativeDurationData.map((series, index) => (
                <Area
                  key={index}
                  type="monotone"
                  dataKey="CumulativeDuration"
                  data={series.data}
                  name={series.name}
                  stroke={
                    chartCumulativeDurationColors[
                      index % chartCumulativeDurationColors.length
                    ]
                  } // Use color based on index
                  fill={
                    chartCumulativeDurationColors[
                      index % chartCumulativeDurationColors.length
                    ]
                  } // Customize the fill color
                />
              ))}
            </AreaChart>
          </ResponsiveContainer>
        </Box>
        <Box
          gridColumn="span 4"
          gridRow="span 2"
          backgroundColor={colors.primary[400]}
          overflow="auto"
        >
          <Box
            mt="25px"
            p="0 30px 20px"
            display="flex "
            justifyContent="space-between"
            alignItems="center"
            borderBottom={`3px solid ${colors.primary[500]}`}
            colors={colors.grey[100]}
          >
            <Box>
              <Typography
                variant="h5"
                fontWeight="600"
                color={colors.grey[100]}
              >
                Statistics
              </Typography>
            </Box>
          </Box>
          <MetaDataStatistics />
        </Box>

        {/* ROW 3 */}
        <Box
          gridColumn="span 4"
          gridRow="span 2"
          backgroundColor={colors.primary[400]}
        >
          <Box
            mt="25px"
            p="0 30px"
            display="flex "
            justifyContent="space-between"
            alignItems="center"
          >
            <Box>
              <Typography
                variant="h5"
                fontWeight="600"
                color={colors.grey[100]}
              >
                Available Sensors
              </Typography>
            </Box>
            <Box>
              <IconButton>
                <DownloadOutlinedIcon
                  sx={{ fontSize: "26px", color: colors.greenAccent[500] }}
                />
              </IconButton>
            </Box>
          </Box>
          <Box height="250px" m="-20px 0 0 0">
            {/*<LineChart isDashboard={true} />*/}
            <ResponsiveContainer>
              <BarChart
                data={chartAvailableSensorsData}
                margin={{ top: 30, right: 30, left: 20, bottom: 5 }}
              >
                <CartesianGrid strokeDasharray="3 3" />
                <XAxis dataKey="sensor" />
                <YAxis />
                <Tooltip />
                <Legend />
                <Bar
                  dataKey="value"
                  fill="#4590F9"
                  barSize={50}
                  name="Number of drives"
                />
              </BarChart>
            </ResponsiveContainer>
          </Box>
        </Box>
        <Box
          gridColumn="span 4"
          gridRow="span 2"
          backgroundColor={colors.primary[400]}
        >
          <Typography
            variant="h5"
            fontWeight="600"
            sx={{ padding: "30px 30px 0 30px" }}
          >
            Night and Day Drives
          </Typography>
          <Box height="250px" mt="-20px">
            <ResponsiveContainer>
              <PieChart>
                <Pie
                  data={chartDayNightData}
                  cx="50%"
                  cy="50%"
                  labelLine={false}
                  label={({ name, value }) => `${name}: ${value}`}
                  fill="#8884d8"
                  dataKey="value"
                >
                  {chartDayNightData.map((entry, index) => (
                    <Cell
                      key={`cell-${index}`}
                      fill={
                        chartDayNightColors[index % chartDayNightColors.length]
                      }
                    />
                  ))}
                </Pie>
                <Tooltip />
                <Legend />
              </PieChart>
            </ResponsiveContainer>
          </Box>
        </Box>
        <Box
          gridColumn="span 4"
          gridRow="span 2"
          backgroundColor={colors.primary[400]}
        >
          <Box height="250px" mt="-20px">
            <MetaDataDisplay />
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export default Dashboard;
