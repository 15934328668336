import { Box, useTheme, Typography, IconButton } from "@mui/material";
import DownloadOutlinedIcon from "@mui/icons-material/DownloadOutlined";
import { tokens } from "../../../theme";
import { useEffect, useState } from "react";
//import { fetchImuData, fetchGpsData, fetchImageFiles } from "./fetchData";
import { fetchImuData, fetchGpsData } from "./fetchData";
import { useParams } from "react-router-dom";
import Header from "../../../global_components/parameterized/Header";

import {
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  ResponsiveContainer,
  Legend,
  LineChart,
  Line,
} from "recharts";
import imagePathsData from "./image_paths.json"; // Import the JSON file containing image paths
import ImageSlider from "./ImageSlider";
import MapPlot from "./MapPlot";

const MetricsPage = () => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);

  const [imudata, setimudata] = useState([]);
  const [gpsData, setGpsData] = useState([]);
  const { driveId, recordingId } = useParams();

  useEffect(() => {
    fetchImuData(driveId, recordingId).then((data) => setimudata(data));
    fetchGpsData(driveId, recordingId).then((data) => setGpsData(data));
  }, [driveId, recordingId]);

  console.log(imudata);

  const a_Data = imudata.map((item) => ({
    timestamp: item.UTCTime, // Assuming timestamp is a field in your data
    a_x: item.Ax,
    a_y: item.Ay,
    a_z: item.Az,
  }));

  // If “gpsData” has one more element than “a_Data”: source of the error.
  // When iterating through gpsData and trying to access a_Data[index], it will eventually reach a point where index exceeds the length of a_Data, resulting in a_Data[index] being undefined.
  // This line checks if “a_Data[index]” exists before trying to access its timestamp property. If it doesn't exist, it assigns null to the x value for that data point.

  const s_Data = gpsData.map((item, index) => ({
    x: (a_Data[index] && a_Data[index].timestamp) || null,
    y: item.speed_kmh,
  }));

  console.log("s_Data", s_Data);

  const p_Data = imudata.map((item) => ({
    timestamp: item.UTCTime,
    PRoll: item.PRoll,
    PPitch: item.PPitch,
    PYaw: item.PYaw,
  }));
  const formatYAxis = (tick) => {
    return tick.toFixed(2); // Format to one decimal place
  };

  const ll_Data = gpsData.map((item, index) => ({
    timestamp: (a_Data[index] && a_Data[index].timestamp) || null,
    LatitudeNMEA: item["LatitudeNMEA"],
    LongitudeNMEA: item["LongitudeNMEA"],
  }));
  console.log("ll_Data", ll_Data);

  const tc_Data = gpsData.map((item, index) => ({
    timestamp: (a_Data[index] && a_Data[index].timestamp) || null,
    TrueCourse: item["TrueCourse"],
  }));
  console.log("tc_Data", tc_Data);

  /* Images */
  const [imagePaths, setImagePaths] = useState([]);
  // Modify the function to read image paths from the imported data
  const importCameraImages = async (driveId, recordingId) => {
    try {
      const paths = imagePathsData[driveId]?.[recordingId] || [];
      return paths;
    } catch (error) {
      console.error("Error importing image paths:", error);
      return [];
    }
  };

  // useEffect remains the same
  useEffect(() => {
    const paths = importCameraImages(driveId, recordingId);
    paths.then((resolvedPaths) => {
      setImagePaths(resolvedPaths);
    });
  }, [driveId, recordingId]);

  return (
    <Box m="20px">
      {/* HEADER */}
      <Box display="flex" justifyContent="space-between">
        <Header
          title="METRICS"
          subtitle="List of metrics for the selected drive"
        />
        <Box display="flex" justifyContent="space-between" gap="10px">
          <Box display="flex" alignItems="center">
            <Box>
              <img
                alt="ipeg-logo"
                width="240px"
                height="55px"
                src={`../../assets/IPeG_Logo.png`}
              />
            </Box>
          </Box>
          <Box display="flex" alignItems="center">
            <Box>
              <img
                alt="dfki-logo"
                width="230px"
                height="53px"
                src={`../../assets/DFKI_Logo_2.png`}
              />
            </Box>
          </Box>
        </Box>
      </Box>

      {/* GRID & CHARTS */}
      <Box
        display="grid"
        gridTemplateColumns="repeat(12, 1fr)"
        gridAutoRows="140px"
        gap="20px"
      >
        {/* ROW 1 */}
        <Box
          gridColumn="span 6"
          gridRow="span 3"
          backgroundColor={colors.primary[400]}
        >
          {/* Acceleration */}
          <Box
            mt="25px"
            p="0 30px"
            display="flex "
            justifyContent="space-between"
            alignItems="center"
          >
            <Box>
              <Typography
                variant="h5"
                fontWeight="600"
                color={colors.grey[100]}
              >
                Acceleration
              </Typography>
            </Box>
            <Box>
              <IconButton>
                <DownloadOutlinedIcon
                  sx={{ fontSize: "26px", color: colors.greenAccent[500] }}
                />
              </IconButton>
            </Box>
          </Box>
          <Box height="380px" m="0 0 0 -40px">
            {/*<LineChart isDashboard={true} />*/}
            <ResponsiveContainer>
              <LineChart
                data={a_Data}
                margin={{ top: 30, right: 30, left: 20, bottom: 5 }}
              >
                <CartesianGrid strokeDasharray="3 3" />
                <XAxis dataKey="timestamp" />
                <YAxis />
                <Tooltip />
                <Legend />
                <Line
                  type="monotone"
                  dataKey="a_x"
                  stroke="#8884d8"
                  name="Acceleration (X)"
                  dot={false}
                  strokeWidth={2}
                />
                <Line
                  type="monotone"
                  dataKey="a_y"
                  stroke="#82ca9d"
                  name="Acceleration (Y)"
                  dot={false}
                  strokeWidth={2}
                />
                <Line
                  type="monotone"
                  dataKey="a_z"
                  stroke="#A6ABBD"
                  name="Acceleration (Z)"
                  dot={false}
                  strokeWidth={2}
                />
              </LineChart>
            </ResponsiveContainer>
          </Box>
        </Box>
        {/* Speed */}
        <Box
          gridColumn="span 6"
          gridRow="span 3"
          backgroundColor={colors.primary[400]}
        >
          <Box
            mt="25px"
            p="0 30px"
            display="flex "
            justifyContent="space-between"
            alignItems="center"
          >
            <Box>
              <Typography
                variant="h5"
                fontWeight="600"
                color={colors.grey[100]}
              >
                Speed
              </Typography>
            </Box>
            <Box>
              <IconButton>
                <DownloadOutlinedIcon
                  sx={{ fontSize: "26px", color: colors.greenAccent[500] }}
                />
              </IconButton>
            </Box>
          </Box>
          <Box height="380px" m="0 0 0 -40px">
            {/*<LineChart isDashboard={true} />*/}
            <ResponsiveContainer>
              <LineChart
                data={s_Data}
                margin={{ top: 30, right: 30, left: 20, bottom: 5 }}
              >
                <CartesianGrid strokeDasharray="3 3" />
                <XAxis dataKey="x" />
                <YAxis />
                <Tooltip />
                <Legend />
                <Line
                  type="monotone"
                  dataKey="y"
                  stroke="#8884d8"
                  name="Speed"
                  dot={false}
                  strokeWidth={2}
                />
              </LineChart>
            </ResponsiveContainer>
          </Box>
        </Box>
        {/* ROW 2 */}
        {/* Orientation */}
        <Box
          gridColumn="span 6"
          gridRow="span 3"
          backgroundColor={colors.primary[400]}
        >
          <Box
            mt="25px"
            p="0 30px"
            display="flex "
            justifyContent="space-between"
            alignItems="center"
          >
            <Box>
              <Typography
                variant="h5"
                fontWeight="600"
                color={colors.grey[100]}
              >
                Orientation
              </Typography>
            </Box>
            <Box>
              <IconButton>
                <DownloadOutlinedIcon
                  sx={{ fontSize: "26px", color: colors.greenAccent[500] }}
                />
              </IconButton>
            </Box>
          </Box>
          <Box height="380px" m="0 0 0 -40px">
            {/*<LineChart isDashboard={true} />*/}
            <ResponsiveContainer>
              <LineChart
                data={p_Data}
                margin={{ top: 30, right: 30, left: 20, bottom: 5 }}
              >
                <CartesianGrid strokeDasharray="3 3" />
                <XAxis dataKey="timestamp" />
                <YAxis tickFormatter={formatYAxis} />
                <Tooltip />
                <Legend />
                <Line
                  type="monotone"
                  dataKey="PRoll"
                  stroke="#8884d8"
                  name="Roll"
                  dot={false}
                  strokeWidth={2}
                />
                <Line
                  type="monotone"
                  dataKey="PPitch"
                  stroke="#82ca9d"
                  name="Pitch"
                  dot={false}
                  strokeWidth={2}
                />
                <Line
                  type="monotone"
                  dataKey="PYaw"
                  stroke="#A6ABBD"
                  name="Yaw"
                  dot={false}
                  strokeWidth={2}
                />
              </LineChart>
            </ResponsiveContainer>
          </Box>
        </Box>
        {/* Latitude and Longitude */}
        <Box
          gridColumn="span 6"
          gridRow="span 3"
          backgroundColor={colors.primary[400]}
        >
          <Box
            mt="25px"
            p="0 30px"
            display="flex "
            justifyContent="space-between"
            alignItems="center"
          >
            <Box>
              <Typography
                variant="h5"
                fontWeight="600"
                color={colors.grey[100]}
              >
                Latitude and Longitude
              </Typography>
            </Box>
            <Box>
              <IconButton>
                <DownloadOutlinedIcon
                  sx={{ fontSize: "26px", color: colors.greenAccent[500] }}
                />
              </IconButton>
            </Box>
          </Box>
          <Box height="380px" m="0 0 0 -40px">
            {/*<LineChart isDashboard={true} />*/}
            <ResponsiveContainer>
              <LineChart
                data={ll_Data}
                margin={{ top: 30, right: 30, left: 20, bottom: 5 }}
              >
                <CartesianGrid strokeDasharray="3 3" />
                <XAxis dataKey="timestamp" />
                <YAxis />
                <Tooltip />
                <Legend />
                <Line
                  type="monotone"
                  dataKey="LatitudeNMEA"
                  stroke="#8884d8"
                  name="Latitude"
                  dot={false}
                  strokeWidth={2}
                />
                <Line
                  type="monotone"
                  dataKey="LongitudeNMEA"
                  stroke="#82ca9d"
                  name="Longitude"
                  dot={false}
                  strokeWidth={2}
                />
              </LineChart>
            </ResponsiveContainer>
          </Box>
        </Box>
        {/* ROW 3 */}
        {/* True Course */}
        <Box
          gridColumn="span 6"
          gridRow="span 3"
          backgroundColor={colors.primary[400]}
        >
          <Box
            mt="25px"
            p="0 30px"
            display="flex "
            justifyContent="space-between"
            alignItems="center"
          >
            <Box>
              <Typography
                variant="h5"
                fontWeight="600"
                color={colors.grey[100]}
              >
                True Course
              </Typography>
            </Box>
            <Box>
              <IconButton>
                <DownloadOutlinedIcon
                  sx={{ fontSize: "26px", color: colors.greenAccent[500] }}
                />
              </IconButton>
            </Box>
          </Box>
          <Box height="380px" m="0 0 0 -40px">
            {/*<LineChart isDashboard={true} />*/}
            <ResponsiveContainer>
              <LineChart
                data={tc_Data}
                margin={{ top: 30, right: 30, left: 20, bottom: 5 }}
              >
                <CartesianGrid strokeDasharray="3 3" />
                <XAxis dataKey="timestamp" />
                <YAxis />
                <Tooltip />
                <Legend />
                <Line
                  type="monotone"
                  dataKey="TrueCourse"
                  stroke="#8884d8"
                  name="True Course"
                  dot={false}
                  strokeWidth={2}
                />
              </LineChart>
            </ResponsiveContainer>
          </Box>
        </Box>
        {/* Images */}
        <Box
          gridColumn="span 6"
          gridRow="span 3"
          backgroundColor={colors.primary[400]}
        >
          <Box
            mt="25px"
            p="0 30px"
            display="flex "
            justifyContent="space-between"
            alignItems="center"
          >
            <Box>
              <Typography
                variant="h5"
                fontWeight="600"
                color={colors.grey[100]}
              >
                Images Preview
              </Typography>
            </Box>
            <Box>
              <IconButton>
                <DownloadOutlinedIcon
                  sx={{ fontSize: "26px", color: colors.greenAccent[500] }}
                />
              </IconButton>
            </Box>
          </Box>
          <Box height="380px" m="40px 0 0 0px">
            <ImageSlider images={imagePaths} />
          </Box>
        </Box>
        {/* Images */}
        <Box
          gridColumn="span 6"
          gridRow="span 3"
          backgroundColor={colors.primary[400]}
        >
          <Box
            mt="25px"
            p="0 30px"
            display="flex "
            justifyContent="space-between"
            alignItems="center"
          >
            <Box>
              <Typography
                variant="h5"
                fontWeight="600"
                color={colors.grey[100]}
              >
                Recording Map
              </Typography>
            </Box>
            <Box>
              <IconButton>
                <DownloadOutlinedIcon
                  sx={{ fontSize: "26px", color: colors.greenAccent[500] }}
                />
              </IconButton>
            </Box>
          </Box>
          <Box height="380px" mt="20px" display="flex" justifyContent="center">
            <MapPlot data={ll_Data} />
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export default MetricsPage;
