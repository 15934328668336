import { ColorModeContext, useMode } from "./theme";
import { CssBaseline, ThemeProvider } from "@mui/material";
import { Routes, Route } from "react-router-dom";
import Dashboard from "./provider/luh/dashboard";
import Topbar from "./global_components/constant/Topbar";
import Sidebar from "./global_components/constant/Sidebar";
import CalendarData from "./provider/luh/calendar";
import Drives from "./provider/luh/drives";
import Recordings from "./provider/luh/recordings";
import GPS from "./provider/luh/gps";
import IMU from "./provider/luh/imu";
import IMAGES from "./provider/luh/images";
import LIDAR from "./provider/luh/lidar";
import TIMESTAMPS from "./provider/luh/timestamps";
import MetricsPage from "./provider/luh/metrics";

import Acknowledgement from "./global_scenes/acknowledgement";
import Imprint from "./global_scenes/imprint";

function App() {
  const [theme, colorMode] = useMode();

  return (
    <ColorModeContext.Provider value={colorMode}>
      <ThemeProvider theme={theme}>
        <CssBaseline />
        <div className="app">
          <Sidebar />
          <main className="content">
            <Topbar />
            <Routes>
              <Route path="/" element={<Dashboard />} />
              <Route path="/drives" element={<Drives />} />
              <Route path="/recordings" element={<Recordings />} />
              <Route path="/gps" element={<GPS />} />
              <Route path="/imu" element={<IMU />} />
              <Route path="/acknowledgement" element={<Acknowledgement />} />
              <Route path="/imprint" element={<Imprint />} />
              <Route path="/images" element={<IMAGES />} />
              <Route path="/lidar" element={<LIDAR />} />
              <Route path="/timestamps" element={<TIMESTAMPS />} />
              <Route path="/calendardata" element={<CalendarData />} />
              <Route path="/metrics" element={<MetricsPage />}>
                <Route path=":driveId/:recordingId" element={<MetricsPage />} />
              </Route>
            </Routes>
          </main>
        </div>
      </ThemeProvider>
    </ColorModeContext.Provider>
  );
}

export default App;
