import { Box } from "@mui/material";
import Header from "../../global_components/parameterized/Header";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import Typography from "@mui/material/Typography";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { Link } from "react-router-dom";

const Acknowledgement = () => {
  return (
    <Box m="20px">
      <Header title="Acknowledgement" subtitle="" />
      <Accordion defaultExpanded>
        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
          <Typography color="#11192A" variant="h5">
            Community
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              height: "100%", // Adjust this value as needed or use a specific height
              width: "100%",
              gap: "50px",
              //flexDirection: "column", // This stacks the images vertically
            }}
          >
            <Link to="https://www.dfg.de/" style={{ textDecoration: "none" }}>
              <img alt="DFG_Logo" src={`../../assets/DFG_Logo.png`} />
            </Link>
            <Link to="https://nfdi4ing.de/" style={{ textDecoration: "none" }}>
              <img
                alt="NFDI4ing_Logo"
                height="110px"
                src={`../../assets/NFDI4ing_Logo.png`}
              />
            </Link>
            <Link
              to="https://www.ipeg.uni-hannover.de/"
              style={{ textDecoration: "none" }}
            >
              <img
                alt="IPeG_Logo"
                height="80px"
                src={`../../assets/IPeG_Logo.png`}
              />
            </Link>
            <Link to="https://www.dfki.de/" style={{ textDecoration: "none" }}>
              <img
                alt="IPeG_Logo"
                height="80px"
                src={`../../assets/DFKI_Logo_2.png`}
              />
            </Link>
          </Box>
        </AccordionDetails>
      </Accordion>
      <Accordion defaultExpanded>
        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
          <Typography color="#11192A" variant="h5">
            Acknowledgement
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            Rayen Hamlaoui would like to thank the Federal Government and the
            Heads of Government of the Länder, as well as the Joint Science
            Conference (GWK), for their funding and support within the framework
            of the NFDI4Ing consortium. Funded by the German Research Foundation
            (DFG) - project number 442146713.
          </Typography>
        </AccordionDetails>
      </Accordion>
    </Box>
  );
};

export default Acknowledgement;
