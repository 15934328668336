import React from "react";
import { Link } from "@mui/material";
import { SidebarFooter } from "react-pro-sidebar";

import "react-pro-sidebar/dist/css/styles.css";
import { Box, Typography } from "@mui/material";

import { Link as RouterLink } from "react-router-dom";

const Footer = () => {
  return (
    <SidebarFooter
      className="Footer"
      style={{ position: "absolute", bottom: 0, width: "100%" }}
    >
      <Box display="flex" alignItems="center" justifyContent="center">
        <Typography sx={{ m: "10px 20px 10px 0px" }}>
          <Link
            component={RouterLink}
            to="/acknowledgement"
            underline="hover"
            color="inherit"
          >
            Acknowledgement
          </Link>
        </Typography>
        <Typography sx={{ m: "10px 20px 10px 0px" }}>
          <Link
            component={RouterLink}
            to="/imprint"
            underline="hover"
            color="inherit"
          >
            Imprint
          </Link>
        </Typography>
      </Box>
      <Box display="flex" alignItems="center" flexDirection="column">
        <Typography sx={{ m: "0px 0px 0px 0px" }}>
          This work has been supported by NFDI4Ing
        </Typography>
        <Typography sx={{ m: "0px 0px 0px 0px" }}>
          (DFG Project Number: 442146713)
        </Typography>
      </Box>
    </SidebarFooter>
  );
};

export default Footer;
