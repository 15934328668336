import React from "react";
import { Box, Button } from "@mui/material";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";

function ImageSlider({ images }) {
  const [currentImageIndex, setCurrentImageIndex] = React.useState(0);
  //const [timestamp, setTimestamp] = React.useState("");

  //   useEffect(() => {
  //     if (images[currentImageIndex]) {
  //       setTimestamp(images[currentImageIndex].split("/").pop().split(".")[0]);
  //     }
  //   }, [currentImageIndex, images]);

  const nextImage = () => {
    setCurrentImageIndex((currentImageIndex + 1) % images.length);
    console.log(images[currentImageIndex]);
  };

  const prevImage = () => {
    setCurrentImageIndex(
      (currentImageIndex - 1 + images.length) % images.length
    );
  };

  return (
    <Box
      display="flex"
      flexDirection="column"
      alignItems="center"
      justifyContent="center"
    >
      <Box
        style={{
          display: "flex",
          flexDirection: "row",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <Button style={{ minWidth: "auto" }} onClick={prevImage}>
          <ChevronLeftIcon />
        </Button>
        <img
          src={images[currentImageIndex]}
          alt=""
          style={{
            maxHeight: "300px", // Adjusted height to fit within the Box
            objectFit: "contain",
          }}
        />
        <Button style={{ minWidth: "auto" }} onClick={nextImage}>
          <ChevronRightIcon />
        </Button>
      </Box>
    </Box>
  );
}

export default ImageSlider;
