export function fetchImuData(driveId: number, recordingId: number) {
  return fetch(
    `${process.env.REACT_APP_BACKEND_URL}/api/data/drives/${driveId}/recordings/${recordingId}/imu`
  )
    .then((response) => response.json())
    .catch((error) => {
      console.error("Error:", error);
    });
}
export function fetchDriveData() {
  return fetch(`${process.env.REACT_APP_BACKEND_URL}/api/drives`)
    .then((response) => response.json())
    .catch((error) => {
      console.error("Error:", error);
    });
}
export function fetchRecordingsForDrive(driveId: number) {
  return fetch(
    `${process.env.REACT_APP_BACKEND_URL}/api/drives/${driveId}/recordings`
  )
    .then((response) => response.json())
    .catch((error) => {
      console.error("Error:", error);
    });
}

export function fetchGpsData(driveId: number, recordingId: number) {
  return fetch(
    `${process.env.REACT_APP_BACKEND_URL}/api/data/drives/${driveId}/recordings/${recordingId}/gps`
  )
    .then((response) => response.json())
    .catch((error) => {
      console.error("Error:", error);
    });
}

export function fetchImageFiles(driveId: number, recordingId: number) {
  return fetch(
    `${process.env.REACT_APP_BACKEND_URL}/api/camera/${driveId}/${recordingId}`
  )
    .then((response) => response.json())
    .catch((error) => {
      console.error("Error:", error);
    });
}
