import React from "react";
import {
  MapContainer,
  TileLayer,
  Polyline,
  Marker,
  Popup,
} from "react-leaflet";
import L from "leaflet";
import "leaflet/dist/leaflet.css";

const startIcon = new L.Icon({
  iconUrl: "/luh/greenmarker.png",
  iconSize: [35, 40],
  iconAnchor: [17, 41],
});

const endIcon = new L.Icon({
  iconUrl: "/luh/redmarker.png",
  iconSize: [35, 40],
  iconAnchor: [17, 41],
});

function dmToDd(dm) {
  const degrees = Math.floor(dm / 100);
  const minutes = dm - degrees * 100;

  return degrees + minutes / 60;
}

function movingAverage(data, n) {
  let result = [];
  for (let i = 0; i < data.length; i++) {
    if (i < n || i > data.length - n - 1) {
      result.push(data[i]); // for the first and last `n` points, use the original data
    } else {
      let sum = 0;
      for (let j = i - n; j <= i + n; j++) {
        sum += data[j];
      }
      result.push(sum / (2 * n + 1)); // replace the point with the average of the surrounding `n` points
    }
  }
  return result;
}

const MapPlot = ({ data }) => {
  const latitudes = data.map((point) => dmToDd(point.LatitudeNMEA));
  const longitudes = data.map((point) => dmToDd(point.LongitudeNMEA));

  const smoothedLatitudes = movingAverage(latitudes, 5);
  const smoothedLongitudes = movingAverage(longitudes, 5);

  const path = smoothedLatitudes.map((lat, idx) => [
    lat,
    smoothedLongitudes[idx],
  ]);
  console.log("path", path);
  if (path.length === 0) {
    return <div>No data to display</div>;
  }

  const midPoint = [
    (path[0][0] + path[path.length - 1][0]) / 2,
    (path[0][1] + path[path.length - 1][1]) / 2,
  ];

  return (
    <MapContainer
      center={midPoint}
      zoom={15}
      style={{ height: "350px", width: "500px" }}
    >
      <TileLayer
        url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
        maxZoom={19}
      />
      <Polyline positions={path} color="blue" />
      <Marker position={path[0]} icon={startIcon}>
        <Popup>Start</Popup>
      </Marker>
      <Marker position={path[path.length - 1]} icon={endIcon}>
        <Popup>End</Popup>
      </Marker>
    </MapContainer>
  );
};

export default MapPlot;
